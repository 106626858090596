import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ButtonsCont d-flex flex-column align-start"},[_c('div',{staticClass:"dropCont mt-3"},[_c(VMenu,{attrs:{"z-index":"3000","offset-x":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn",attrs:{"rounded":"","large":"","depressed":"","outlined":"","color":"#003D6D"}},'v-btn',attrs,false),on),[_vm._v(" Send Email ")])]}}])},[_c('div',{staticClass:"ListFh"},[_c(VList,{staticClass:"listContent"},[_c('div',{staticClass:"pl-5 pr-5"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"button d-flex justify-start align-center",on:{"click":function($event){return _vm.defineQuotation(item, item.id)}}},[_vm._v(" "+_vm._s(item.description)+" ")])}),0),_c('div',{staticClass:"declineOffer"},[_c('div',{staticClass:"\n                buttoNoBorder\n                d-flex\n                justify-start\n                align-center\n                pl-5\n                pr-5\n              "},[_vm._v(" Ask for more information ")])])])],1)])],1),_c('EmailModal',{ref:"emailModal",attrs:{"headerEmail":_vm.reason,"item":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }