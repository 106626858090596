import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExchangeCalcCont d-flex justify-center flex-wrap align-center"},[_vm._m(0),_c('div',{staticClass:"ExchangeCont"},[_c('div',{staticClass:"TableCont"},[_c('div',{staticClass:"LabelsColumn"},[_c('div',{staticClass:"LabelsColumn__TitleSpace"}),_c('div',{staticClass:"LabelsColumn__Labels"},[_vm._v("Property Damage")]),_c('div',{staticClass:"LabelsColumn__Labels"},[_vm._v("Business interruption")]),(_vm.displayStocks)?_c('div',{staticClass:"LabelsColumn__Labels"},[_vm._v("Stocks")]):_vm._e()]),_c('div',{staticClass:"NormalColumn"},[_c('div',{staticClass:"titleCont"},[_vm._v("Original Currency")]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Property Damage")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.currentCurrency.key,"errorMessages":_vm.quotationValids('tiv', 'propertyDamage')},on:{"blur":function($event){_vm.calcule();
                _vm.setStateTIV('propertyDamage', this);
                _vm.checkInsurableColumn('propertyDamage');}},model:{value:(_vm.tiv.propertyDamage),callback:function ($$v) {_vm.$set(_vm.tiv, "propertyDamage", $$v)},expression:"tiv.propertyDamage"}})],1)]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Business interruption")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.currentCurrency.key,"errorMessages":_vm.quotationValids('tiv', 'businessInterruption')},on:{"blur":function($event){_vm.calcule();
                _vm.setStateTIV('businessInterruption', this);
                _vm.checkInsurableColumn('businessInterruption');}},model:{value:(_vm.tiv.businessInterruption),callback:function ($$v) {_vm.$set(_vm.tiv, "businessInterruption", $$v)},expression:"tiv.businessInterruption"}})],1)]),(_vm.displayStocks)?_c('div',{staticClass:"Stocks"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Stocks")]),_c('div',{staticClass:"InputStocks"},[_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.currentCurrency.key,"errorMessages":_vm.quotationValids('tiv', 'stock')},model:{value:(_vm.stock),callback:function ($$v) {_vm.stock=$$v},expression:"stock"}})],1),_c('div',{staticClass:"Percentaje"},[_c(VTextField,{attrs:{"type":"number","suffix":"%","error-messages":_vm.quotationValids('tiv', 'porcentaje'),"max":"100"},on:{"blur":function($event){_vm.setStateTIV('porcentaje', this);
                  _vm.checkInsurableColumn('porcentaje');}},model:{value:(_vm.tiv.porcentaje),callback:function ($$v) {_vm.$set(_vm.tiv, "porcentaje", $$v)},expression:"tiv.porcentaje"}})],1)])]):_vm._e(),_c('div',{staticClass:"Total"},[_vm._v(_vm._s(_vm.tivTotal))])]),_c('div',{staticClass:"NormalColumn"},[_c('div',{staticClass:"titleCont"},[_vm._v("USD")]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Property Damage")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":"USD","readonly":""},model:{value:(_vm.propertyDamageUsd),callback:function ($$v) {_vm.propertyDamageUsd=$$v},expression:"propertyDamageUsd"}})],1)]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Business interruption")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":"USD","readonly":""},model:{value:(_vm.businessInterruptionUsd),callback:function ($$v) {_vm.businessInterruptionUsd=$$v},expression:"businessInterruptionUsd"}})],1)]),(_vm.displayStocks)?_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Stocks")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":"USD","readonly":""},model:{value:(_vm.stockUsd),callback:function ($$v) {_vm.stockUsd=$$v},expression:"stockUsd"}})],1)]):_vm._e(),_c('div',{staticClass:"Total"},[_vm._v(_vm._s(_vm.tivTotalUsd))])]),_c('div',{staticClass:"Separador HideOnMovil"})])]),_c('div',{staticClass:"ExpansionLineTop mt-3 HideOnMovil"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TitleComponent d-flex justify-start align-center"},[_c('h5',[_vm._v("Total insurable value")])])}]

export { render, staticRenderFns }