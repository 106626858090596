import { render, staticRenderFns } from "./StepperEndorsements.vue?vue&type=template&id=f88b0bf4&scoped=true&"
import script from "./StepperEndorsements.vue?vue&type=script&lang=js&"
export * from "./StepperEndorsements.vue?vue&type=script&lang=js&"
import style0 from "./StepperEndorsements.vue?vue&type=style&index=0&id=f88b0bf4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88b0bf4",
  null
  
)

export default component.exports