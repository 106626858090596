import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExpandContent",attrs:{"disabled":_vm.loadingPanel}},[_c(VForm,{staticClass:"Form d-flex justify-start align-center flex-wrap"},[_c('div',{staticClass:"InputContent"},[_c(VTextField,{attrs:{"label":"Insured","readonly":""},on:{"blur":function($event){return _vm.setStateQuotation('insuredName', this)}},model:{value:(_vm.accountInformation.insuredName),callback:function ($$v) {_vm.$set(_vm.accountInformation, "insuredName", $$v)},expression:"accountInformation.insuredName"}})],1),_c('div',{staticClass:"InputContent"},[_c(VSelect,{attrs:{"required":"","label":"Currency","items":_vm.currencies,"item-text":"key","item-value":"id","hint":((_vm.currentCurrency.region) + ", " + (_vm.currentCurrency.description)),"persistent-hint":"","return-object":"","loading":_vm.loadingCurrencies,"disabled":_vm.currencies.length === 0,"readonly":""},on:{"blur":function($event){return _vm.setStateQuotation('currency', this)}},model:{value:(_vm.accountInformation.currency),callback:function ($$v) {_vm.$set(_vm.accountInformation, "currency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountInformation.currency"}})],1),_c('div',{staticClass:"InputContent"},[_c(VTextField,{attrs:{"label":"Exchange Rate","type":"number","error-messages":_vm.requiredQuotation('exchangeRate')},on:{"blur":function($event){_vm.setStateQuotation('exchangeRate', this);
          _vm.checkQuotationColumn('exchangeRate');}},model:{value:(_vm.quotation.exchangeRate),callback:function ($$v) {_vm.$set(_vm.quotation, "exchangeRate", $$v)},expression:"quotation.exchangeRate"}})],1),_c('div',{staticClass:"InputContent"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto","content-class":"elevation-3","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Inception Date","readonly":""},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"#003D6D","error-messages":_vm.requiredQuotation('inceptionDate')},on:{"input":function($event){(_vm.menu2 = false),
              _vm.setStateQuotation('inceptionDate', this),
              _vm.checkQuotationColumn('inceptionDate')}},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}})],1)],1),_c('div',{staticClass:"InputContent"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto","content-class":"elevation-3","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Expiry Date","readonly":""},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"#003D6D","error-messages":_vm.requiredQuotation('expiryDate')},on:{"input":function($event){(_vm.menu = false),
              _vm.setStateQuotation('expiryDate', this),
              _vm.checkQuotationColumn('expiryDate')}},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }