import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"SubExpansionComponent",attrs:{"disabled":_vm.disabledPanel},model:{value:(_vm.currentPanel),callback:function ($$v) {_vm.currentPanel=$$v},expression:"currentPanel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle d-flex justify-start",attrs:{"expand-icon":""},on:{"click":_vm.changeSubExpansion},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"subIconCont d-flex justify-start align-center"},[_c(VIcon,{staticClass:"iconExpand mr-1"},[_vm._v(" "+_vm._s(_vm.iconSubExpansion)+" ")]),_c('div',{staticClass:"SubExpansionTitle"},[_vm._v(_vm._s(_vm.componentTitle))])],1)]},proxy:true}])}),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('div',{staticClass:"InputsCont d-flex justify-start flex-wrap"},[_c(VAutocomplete,{attrs:{"label":"Movement Type","items":_vm.catalogEndorsements,"item-value":"id","item-text":"description","disabled":_vm.disabledSelect},model:{value:(_vm.movementsValue),callback:function ($$v) {_vm.movementsValue=$$v},expression:"movementsValue"}}),_c(VBtn,{staticClass:"btn ml-5",attrs:{"rounded":"","text":"","color":"#003D6D"},on:{"click":function($event){return _vm.$emit('removeField', _vm.endorsementId)}}},[_vm._v(" Delete Movement ")])],1),(_vm.showComponent)?_c(_vm.dynamicComponent,{ref:"componente",tag:"component",attrs:{"movementId":_vm.movementId}}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }