import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VExpansionPanels,{staticClass:"ExpansionComponent ExpansionBordered mt-6",attrs:{"disabled":_vm.loading},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c(VExpansionPanel,{on:{"change":_vm.deepDisabled}},[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" Extensions and Endorsements "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('Policies',{on:{"updateSluShare":_vm.updateSluShare}}),_vm._l((_vm.endorsements),function(item,index){return _c('Movement',{key:index,attrs:{"endorsementId":item.id,"movementType":item.movement_type,"movementId":item.movement_id,"componentTitle":_vm.getDescription(item),"indexarr":index},on:{"movementValueChanged":_vm.valueChanged,"removeField":_vm.removeField,"changeMovementType":function($event){return _vm.changeMovementType($event, index)},"changeMovementId":function($event){return _vm.changeMovementId($event, index)}}})}),_c('div',{staticClass:"ButtonCont mt-2 d-flex justify-start align-center"},[_c(VBtn,{staticClass:"Btn",attrs:{"rounded":"","text":"","disabled":!_vm.btnvalid},on:{"click":function($event){return _vm.addNewField()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Add New Movement ")],1)],1)],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }