import { render, staticRenderFns } from "./TypeEndorsement.vue?vue&type=template&id=0e4fe181&scoped=true&"
import script from "./TypeEndorsement.vue?vue&type=script&lang=js&"
export * from "./TypeEndorsement.vue?vue&type=script&lang=js&"
import style0 from "./TypeEndorsement.vue?vue&type=style&index=0&id=0e4fe181&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4fe181",
  null
  
)

export default component.exports