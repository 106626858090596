import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"SubExpansionComponent"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle d-flex justify-start",attrs:{"expand-icon":""},on:{"click":_vm.changeSubExpansion},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"subIconCont d-flex justify-start align-center"},[_c(VIcon,{staticClass:"iconExpand mr-1"},[_vm._v(" "+_vm._s(_vm.iconSubExpansion)+" ")]),_c('div',{staticClass:"SubExpansionTitle"},[_vm._v("Movement Without Premium")])],1)]},proxy:true}])}),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent justify-center"},[_c('div',{staticClass:"InputsCont d-flex justify-start flex-wrap"},[_c('div',{staticClass:"InputCont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Effective Date Movement"},model:{value:(_vm.$v.movementWithoutPremium.effectiveDateMovement.$model),callback:function ($$v) {_vm.$set(_vm.$v.movementWithoutPremium.effectiveDateMovement, "$model", $$v)},expression:"$v.movementWithoutPremium.effectiveDateMovement.$model"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.$v.movementWithoutPremium.effectiveDateMovement.$model),callback:function ($$v) {_vm.$set(_vm.$v.movementWithoutPremium.effectiveDateMovement, "$model", $$v)},expression:"$v.movementWithoutPremium.effectiveDateMovement.$model"}})],1)],1),_c('div',{staticClass:"InputCont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Movement End Date"},model:{value:(_vm.$v.movementWithoutPremium.movementEndDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.movementWithoutPremium.movementEndDate, "$model", $$v)},expression:"$v.movementWithoutPremium.movementEndDate.$model"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.$v.movementWithoutPremium.movementEndDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.movementWithoutPremium.movementEndDate, "$model", $$v)},expression:"$v.movementWithoutPremium.movementEndDate.$model"}})],1)],1)]),_c('div',{staticClass:"TitleTextArea"},[_vm._v("PML Comments")]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.movementWithoutPremium.pmlComments.$model),expression:"$v.movementWithoutPremium.pmlComments.$model",modifiers:{"trim":true}}],domProps:{"value":(_vm.$v.movementWithoutPremium.pmlComments.$model)},on:{"blur":[function($event){_vm.setMovementWithoutPremium({ index: _vm.currentIndex, key: 'pmlComments', value: _vm.$v.movementWithoutPremium.pmlComments.$model });
            _vm.checkField('pmlComments');},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.movementWithoutPremium.pmlComments, "$model", $event.target.value.trim())}}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }